// This first quick and dirty attempt at a standard print style was created for http://www.lib.uiowa.edu/iwa/kerber/
// Hopefully, it works everywhere. I will be back.  -Ken

html,
body {
	background: none !important;
}

#footer,
#header,
#nav,
#top-menu,
#la_wrapper {
	display: none;
}

#printheader {
	display: block;
}

#main,
#content .page {
	border: none;
	background: none !important;
}


