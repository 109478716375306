html,
body {
  background: none !important;
}

#footer,
#header,
#nav,
#top-menu,
#la_wrapper {
  display: none;
}

#printheader {
  display: block;
}

#main,
#content .page {
  border: none;
  background: none !important;
}